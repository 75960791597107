import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { useGetSelfQuery } from './usersApi';

const initialState = { token: new Cookies().get('token') ?? null, user: null };

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      new Cookies().set('token', action.payload, { path: '/' });
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const selectUser = (state) => state.authReducer.user;
export const selectUserToken = (state) => state.authReducer.token;
export const { setToken, setUser } = authSlice.actions;
export default authSlice.reducer;
