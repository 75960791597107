import React, { useEffect, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { useUploadPostPhotosMutation } from 'services/uploadApi';
export const Editor = ({ text, onChangeText }) => {
  const { id } = useParams();

  const quillRef = useRef();
  const [uploadPostPhoto, results] = useUploadPostPhotosMutation();

  useEffect(() => {
    if (results.isSuccess) {
      const editor = quillRef.current.getEditor();
      editor.insertEmbed(
        editor.getSelection(),
        'image',
        results.data.post.images[results.data.post.images.length - 1].image.url
      );
    }
  }, [results]);

  const imageHandler = (e) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append('content', file);
        uploadPostPhoto({ data: formData, id: id });
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <>
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={text}
        modules={modules}
        onChange={onChangeText}
        readOnly={id ? false : true}
        defaultValue=""
      />
    </>
  );
};
