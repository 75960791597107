import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.mytraveldinner.com/api/v1/',
  }),
  endpoints: (builder) => ({
    authLogin: builder.mutation({
      query: ({ data }) => ({
        url: `auth/login`,
        method: 'POST',
        body: { user: data },
      }),
    }),
    sendVerificationToken: builder.mutation({
      query: ({ data }) => ({
        url: `auth/resend`,
        method: 'POST',
        body: { user: data },
      }),
    }),
  }),
});

export const {
  useAuthLoginMutation,
  useSendVerificationTokenMutation
} = authApi;
