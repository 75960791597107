import { RestaurantColumns } from 'features/table/headers/Restaurants';
import { useGetAllRestaurantsQuery } from 'services/restaurantsApi';
import { UsersColumns } from 'features/table/headers/Users';
import { useGetAllUsersQuery } from 'services/usersApi';
import { CategoryColumns } from 'features/table/headers/Categories';
import { useGetAllCategoriesQuery } from 'services/categoriesApi';
import { CountryColumns } from 'features/table/headers/Countries';
import { useGetAllCountriesQuery } from 'services/countriesApi';
import { RegionColumns } from 'features/table/headers/Regions';
import { useGetAllRegionsQuery } from 'services/regionsApi';
import { PostColumns } from 'features/table/headers/Posts';
import { useGetAllPostsQuery } from 'services/postsApi';
export const TableRoutes = [
  {
    property: 'restaurants',
    columns: RestaurantColumns,
    queryFunction: useGetAllRestaurantsQuery,
  },
  {
    property: 'users',
    columns: UsersColumns,
    queryFunction: useGetAllUsersQuery,
  },
  {
    property: 'categories',
    columns: CategoryColumns,
    queryFunction: useGetAllCategoriesQuery,
  },
  {
    property: 'countries',
    columns: CountryColumns,
    queryFunction: useGetAllCountriesQuery,
  },
  {
    property: 'regions',
    columns: RegionColumns,
    queryFunction: useGetAllRegionsQuery,
  },
  {
    property: 'posts',
    columns: PostColumns,
    queryFunction: useGetAllPostsQuery,
  },
];
