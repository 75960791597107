import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from 'app/Navbar';
import { Helmet } from 'react-helmet';
import { Table } from 'features/table/Table';
import { TableRoutes } from 'routes/TableRoutes';
import { Content } from 'app/Content';
import { ThemeProvider } from '@mui/material';
import { TableTheme } from 'services/themes';
import { CreateUpdateViewRoutes } from 'routes/EditRoutes';
import { SnackbarProvider } from 'notistack';
import { Login } from 'features/login/Login';
import ProtectedRoutes from 'routes/PrivateRoutes';
import PublicRoutes from 'routes/PublicRoutes';
function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>My Travel Dinner | Admin</title>
      </Helmet>
      <div className="App">
        <SnackbarProvider maxSnack={5}>
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              {CreateUpdateViewRoutes.map((route) => {
                return (
                  <Route
                    key={route.property}
                    exact
                    path={`/admin/${route.property}/:action`}
                    element={route.element}
                  />
                );
              })}

            {CreateUpdateViewRoutes.map((route) => {
              return (
                <Route
                  key={route.property}
                  exact
                  path={`/admin/${route.property}/:action/:id`}
                  element={route.element}
                />
              );
            })}

            {TableRoutes.map((route) => {
              return (
                <Route
                  key={route.property}
                  exact
                  path={`/admin/${route.property}`}
                  element={
                    <>
                      <ThemeProvider theme={TableTheme}>
                        <Navbar />
                        <Content title={route.property}>
                          <Table
                            property={route.property}
                            columns={route.columns}
                            useQueryFunction={route.queryFunction}
                          />
                        </Content>
                      </ThemeProvider>
                    </>
                  }
                />
              );
            })}
            </Route>
            <Route path="/auth" element={<PublicRoutes />}>
              <Route path="/auth/login" element={<Login />} />
            </Route>
            <Route path="*" element={<div>Not found</div>} />
          </Routes>
        </SnackbarProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
