import { Button } from '@mui/material';
import React from 'react';

export const UsersColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name of the user',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.profile?.name,
  },
  {
    field: 'email',
    headerName: 'Email',
    description: 'Email of the user',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
  },
  {
    field: 'address',
    headerName: 'Address',
    description: 'Address of the user',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.address,
  },
  {
    field: 'city',
    headerName: 'City',
    description: 'City of the user',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.city,
  },
  {
    field: 'country',
    headerName: 'Country',
    description: 'Country of the user',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.country,
  },
  {
    field: 'role',
    headerName: 'Role',
    description: 'Role of the user',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.role?.name,
  },
  {
    field: 'details',
    headerName: 'Details',
    description: 'Link to the user details',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      return (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: '10%' }}
            href={`/admin/users/edit/${params.id}`}
          >
            Edit
          </Button>
        </strong>
      );
    },
  },
];
