import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { InfoPanel } from 'features/create-update-view/tabs/Info/Info';
import { ImagesPanel } from 'features/create-update-view/tabs/Images/Images';
import { QrPanel } from 'features/create-update-view/tabs/QRCode/QrCode';
import { PeoplePanel } from 'features/create-update-view/tabs/People/People';
import { useParams } from 'react-router-dom';
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from 'services/categoriesApi';
import {
  useCreateRestaurantMutation,
  useDeleteRestaurantMutation,
  useUpdateRestaurantMutation,
} from 'services/restaurantsApi';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} from 'services/usersApi';
import {
  useCreateCountryMutation,
  useDeleteCountryMutation,
  useUpdateCountryMutation,
} from 'services/countriesApi';
import {
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useUpdateRegionMutation,
} from 'services/regionsApi';
import {
  useCreatePostMutation,
  useDeletePostMutation,
  useUpdatePostMutation,
} from 'services/postsApi';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function MainView({ useGetQueryFunction, property }) {
  const [value, setValue] = useState(0);
  const { id, action } = useParams();

  const { data, isSuccess } = useGetQueryFunction(id ?? '');

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const views = {
    restaurants: [
      {
        label: 'Info',
        element: (
          <InfoPanel
            data={data?.restaurant}
            useMutation={
              action === 'edit' ? useUpdateRestaurantMutation : useCreateRestaurantMutation
            }
            contentFor={'restaurants'}
            useDeleteMutation={useDeleteRestaurantMutation}
          />
        ),
      },
      {
        label: 'Images',
        element: <ImagesPanel id={data?.restaurant?.id} />,
      },
      {
        label: 'QR Code',
        element: <QrPanel data={data?.restaurant} />,
      },
      {
        label: 'People',
        element: <PeoplePanel data_={data?.restaurant} />,
      },
    ],
    users: [
      {
        label: 'Info',
        element: (
          <InfoPanel
            data={data?.user}
            useMutation={action === 'edit' ? useUpdateUserMutation : useCreateUserMutation}
            useDeleteMutation={useDeleteUserMutation}
            contentFor={'users'}
          />
        ),
      },
    ],
    categories: [
      {
        label: 'Info',
        element: (
          <InfoPanel
            data={data?.category}
            useMutation={action === 'edit' ? useUpdateCategoryMutation : useCreateCategoryMutation}
            contentFor={'categories'}
            useDeleteMutation={useDeleteCategoryMutation}
          />
        ),
      },
    ],
    countries: [
      {
        label: 'Info',
        element: (
          <InfoPanel
            data={data?.country}
            useMutation={action === 'edit' ? useUpdateCountryMutation : useCreateCountryMutation}
            contentFor={'countries'}
            useDeleteMutation={useDeleteCountryMutation}
          />
        ),
      },
    ],
    regions: [
      {
        label: 'Info',
        element: (
          <InfoPanel
            data={data?.region}
            useMutation={action === 'edit' ? useUpdateRegionMutation : useCreateRegionMutation}
            contentFor={'regions'}
            useDeleteMutation={useDeleteRegionMutation}
          />
        ),
      },
    ],
    posts: [
      {
        label: 'Info',
        element: (
          <InfoPanel
            data={data?.post}
            useMutation={action === 'edit' ? useUpdatePostMutation : useCreatePostMutation}
            useDeleteMutation={useDeletePostMutation}
            contentFor="posts"
          />
        ),
      },
    ],
  };

  return (
    <>
      {isSuccess && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {property === 'restaurants' ? (
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {views[property].map((view, index) => {
                  if (action === 'edit')
                    return <Tab label={view.label} key={view.label} {...a11yProps(index)} />;
                  else if (view.label === 'Info')
                    return <Tab label={view.label} key={view.label} {...a11yProps(index)} />;
                  else return <></>;
                })}
              </Tabs>
            ) : null}
          </Box>
          {views[property].map((view, index) => {
            if (action === 'edit') return <Box key={index}>{value === index && view.element}</Box>;
            else if (view.label === 'Info')
              return <Box key={index}>{value === index && view.element}</Box>;
            else return <></>;
          })}
        </Box>
      )}
    </>
  );
}
