import { PhotoCamera, ClearOutlined } from '@mui/icons-material';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  Select,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Editor } from 'app/Editor';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser } from 'services/authSlice';
import { useGetAllCategoriesQuery } from 'services/categoriesApi';
import { useGetAllCountriesQuery } from 'services/countriesApi';
import { useTranslatePostMutation } from 'services/postsApi';
import { useGetAllRegionsQuery } from 'services/regionsApi';
import { useDeletePostPhotoMutation, useUploadPostPhotosMutation } from 'services/uploadApi';

export const PostContent = ({ formValues, setFormValues }) => {
  const user = useSelector(selectUser);
  const { id, action } = useParams();
  const categoriesData = useGetAllCategoriesQuery();
  const countriesData = useGetAllCountriesQuery();
  const regionsData = useGetAllRegionsQuery();
  const [uploadPhoto, results] = useUploadPostPhotosMutation();
  const [deletePhoto, deleteResults] = useDeletePostPhotoMutation();
  const [translate, translateResults] = useTranslatePostMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [text, setText] = useState('');

  const htmlDecode = (value) => {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    return doc.documentElement.textContent;
  };

  // useEffect(() => {
  //   if (formValues?.author === null) setFormValues({ ...formValues, author: user });
  // }, [user]);

  useEffect(() => {
    if (!formValues?._publishDate) {
      setFormValues({ ...formValues, _publishDate: moment(formValues?.publishDate) });
    }
  }, [formValues]);

  const upload = (e, type) => {
    const newPhoto = new FormData();
    newPhoto.append(type, e.target.files[0]);
    uploadPhoto({ id: formValues.id, data: newPhoto });
  };

  useEffect(() => {
    if (deleteResults.isSuccess) {
      enqueueSnackbar(`Successfully deleted`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setFormValues({
        ...formValues,
        images: deleteResults.data?.post?.images,
      });
    }

    if (deleteResults.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [deleteResults]);

  useEffect(() => {
    if (results.isSuccess) {
      enqueueSnackbar(`Successfully uploaded`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setFormValues({
        ...formValues,
        images: results.data?.post?.images,
      });
    }

    if (results.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results]);

  const handleLanguageChange = (e) => {
    e.preventDefault();
    const translation = formValues?.translations?.filter(
      (translation) => translation.language === e.target.value
    )[0];
    setFormValues({
      ...formValues,
      language: e.target.value,
      title: translation?.title,
      subtitle: translation?.subtitle,
      content: translation?.content,
    });
    setText(htmlDecode(translation?.content));
  };

  useEffect(() => {
    let newTranslations = null;
    if (formValues?.translations?.length > 0) {
      newTranslations = formValues?.translations?.filter(
        (translation) => translation.language !== formValues.language
      );
      newTranslations.push({
        language: formValues?.language,
        title: formValues?.title,
        subtitle: formValues?.subtitle,
        content: text,
      });
    }
    setFormValues({
      ...formValues,
      content: text,
      translations: newTranslations === null ? formValues?.translations : newTranslations,
    });
  }, [text]);

  useEffect(() => {
    if (translateResults.isSuccess) {
      enqueueSnackbar(`Successfully translated`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setFormValues(translateResults.data?.post);
    }

    if (translateResults.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [translateResults]);

  const getImage = (type) => {
    const image = formValues?.images?.filter((image) => image[type] === true);
    if (image?.length > 0) return image[0].image;
  };

  const getCarouselImages = () => formValues?.images?.filter((image) => image.carousel === true);

  const getPublishedDate = () => {
    if (formValues?.publishDate) {
      const date = new Date(Date.parse(formValues?.publishDate));
      return date.getMonth() + '-' + date.getDay() + '-' + date.getFullYear();
    }
  };

  return (
    <>
      <Grid container direction="row" spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="General" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid
                container
                justifyContent="center"
                direction="row"
                spacing={4}
                alignItems="center"
              >
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="categpry-name-select">Select Category</InputLabel>
                    <Select
                      variant="standard"
                      id="category-select"
                      onChange={(e) =>
                        setFormValues({ ...formValues, category: { id: e.target.value } })
                      }
                      value={formValues?.category?.id ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                      endAdornment={
                        <IconButton
                          sx={{ display: formValues?.category?.id ? '' : 'none', marginRight: 2 }}
                          onClick={() => {
                            setFormValues({ ...formValues, category: { id: null } });
                          }}
                        >
                          <ClearOutlined />
                        </IconButton>
                      }
                    >
                      {categoriesData.data?.categories.map((category) => (
                        <MenuItem
                          key={category.id}
                          value={category.id}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="categpry-text">Category name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-name-select">Select Country</InputLabel>
                    <Select
                      variant="standard"
                      id="country-select"
                      onChange={(e) =>
                        setFormValues({ ...formValues, country: { id: e.target.value } })
                      }
                      value={formValues?.country?.id ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                      endAdornment={
                        <IconButton
                          sx={{ display: formValues?.country?.id ? '' : 'none', marginRight: 2 }}
                          onClick={() => {
                            setFormValues({ ...formValues, country: { id: null } });
                          }}
                        >
                          <ClearOutlined />
                        </IconButton>
                      }
                    >
                      {countriesData.data?.countries.map((country) => (
                        <MenuItem
                          key={country.id}
                          value={country.id}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="categpry-text">Country name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="region-name-select">Select Region</InputLabel>
                    <Select
                      variant="standard"
                      id="region-select"
                      onChange={(e) =>
                        setFormValues({ ...formValues, region: { id: e.target.value } })
                      }
                      value={formValues?.region?.id ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                      endAdornment={
                        <IconButton
                          sx={{ display: formValues?.region?.id ? '' : 'none', marginRight: 2 }}
                          onClick={() => {
                            setFormValues({ ...formValues, region: { id: null } });
                          }}
                        >
                          <ClearOutlined />
                        </IconButton>
                      }
                    >
                      {regionsData.data?.regions.map((region) => (
                        <MenuItem
                          key={region.id}
                          value={region.id}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {region.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="region-text">Region name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  {action === 'edit' && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues?.status === 'draft' ? false : true}
                          onChange={() =>
                            setFormValues({
                              ...formValues,
                              status: formValues.status === 'draft' ? 'published' : 'draft',
                            })
                          }
                          disabled={action === 'edit' ? false : true}
                        />
                      }
                      label={formValues?.status === 'draft' ? 'Draft' : 'Published'}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {action === 'edit' && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Publish date"
                        inputFormat="MM-DD-YYYY"
                        value={formValues?._publishDate ?? ''}
                        onChange={(newValue) => {
                          setFormValues({
                            ...formValues,
                            _publishDate: newValue,
                            publishDate: newValue.format('MM-DD-YYYY'),
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                </Grid>
                <Grid item xs={4} sx={{ display: formValues?.author != null ? 'flex' : 'none' }}>
                  <Typography
                    sx={{ textTransform: 'capitalize' }}
                  >{`Author: ${formValues?.author?.profile?.name} ${formValues?.author?.profile?.lastName}`}</Typography>
                  <Typography>{`(${formValues?.author?.email})`}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Video" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid
                container
                justifyContent="center"
                direction="row"
                spacing={4}
                alignItems="center"
              >
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="video-url-input">Video URL</InputLabel>
                    <Input
                      id="video-url-input"
                      aria-describedby="video-url-input-text"
                      value={formValues?.videoUrl ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          videoUrl: e.target.value,
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="video-url-input-help">Video URL.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Images" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid
                container
                justifyContent="center"
                direction="row"
                spacing={4}
                alignItems="center"
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Grid container direction={'row'} alignItems="center" justifyContent="center">
                    <Typography>Cover image</Typography>
                    {action === 'edit' && (
                      <InputLabel htmlFor="cover-button-file">
                        <Input
                          style={{ display: 'none' }}
                          id="cover-button-file"
                          name="cover-button-file"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            upload(e, 'cover');
                          }}
                        />
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <PhotoCamera />
                        </IconButton>
                      </InputLabel>
                    )}
                  </Grid>
                  <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
                    {formValues?.images?.map((image, idx) => {
                      if (image.cover === true) {
                        return (
                          <ImageListItem
                            key={idx}
                            sx={{ boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}
                          >
                            <img
                              src={image?.image?.url}
                              srcSet={image?.image?.url}
                              alt={image?.image?.name}
                            />
                            <ImageListItemBar
                              key={`ili${image?.image?.url}`}
                              actionIcon={
                                <IconButton
                                  key={`ib${image?.image?.url}`}
                                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                  aria-label={`info about ${image?.image?.name}`}
                                  onClick={() => deletePhoto({ id: id, imageid: image?.image?.id })} //TODO: Delete function
                                >
                                  <ClearOutlined />
                                </IconButton>
                              }
                              title={`Cover image`}
                            />
                          </ImageListItem>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </ImageList>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Grid container direction={'row'} alignItems="center" justifyContent="center">
                    <Typography>Main image</Typography>
                    {action === 'edit' && (
                      <InputLabel htmlFor="main-button-file">
                        <Input
                          style={{ display: 'none' }}
                          id="main-button-file"
                          name="main-button-file"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            upload(e, 'main');
                          }}
                        />
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <PhotoCamera />
                        </IconButton>
                      </InputLabel>
                    )}
                  </Grid>
                  <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
                    {formValues?.images?.map((image, idx) => {
                      if (image.main === true) {
                        return (
                          <ImageListItem
                            key={idx}
                            sx={{ boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}
                          >
                            <img
                              src={image?.image?.url}
                              srcSet={image?.image?.url}
                              alt={image?.image?.name}
                            />
                            <ImageListItemBar
                              key={`ili${image?.image?.url}`}
                              actionIcon={
                                <IconButton
                                  key={`ib${image?.image?.url}`}
                                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                  aria-label={`info about ${image?.image?.name}`}
                                  onClick={() => deletePhoto({ id: id, imageid: image?.image?.id })} //TODO: Delete function
                                >
                                  <ClearOutlined />
                                </IconButton>
                              }
                              title={`Main image`}
                            />
                          </ImageListItem>
                        );
                      }
                    })}
                  </ImageList>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction={'row'} alignItems="center" justifyContent="center">
                    <Typography>Carousel</Typography>
                    {action === 'edit' && (
                      <InputLabel htmlFor="carousel-button-file">
                        <Input
                          style={{ display: 'none' }}
                          id="carousel-button-file"
                          name="carousel-button-file"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            upload(e, 'carousel');
                          }}
                        />
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <PhotoCamera />
                        </IconButton>
                      </InputLabel>
                    )}
                  </Grid>
                  <ImageList gap={48} cols={2} sx={{ marginX: 4 }}>
                    {getCarouselImages()?.map((image, idx) => (
                      <ImageListItem
                        key={idx}
                        sx={{ boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}
                      >
                        <img
                          src={image?.image?.url}
                          srcSet={image?.image?.url}
                          alt={image?.image?.name}
                        />
                        <ImageListItemBar
                          key={`ili${image?.image?.url}`}
                          actionIcon={
                            <IconButton
                              key={`ib${image?.image?.url}`}
                              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                              aria-label={`info about ${image?.image?.name}`}
                              onClick={() => deletePhoto({ id: id, imageid: image?.image?.id })} //TODO: Delete function
                            >
                              <ClearOutlined />
                            </IconButton>
                          }
                          title={`Carousel image`}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Content" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid
                container
                justifyContent="center"
                direction="row"
                spacing={4}
                alignItems="center"
              >
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="language-name-select">Language</InputLabel>
                    <Select
                      variant="standard"
                      id="language-select"
                      onChange={(e) => handleLanguageChange(e)}
                      value={formValues?.language ?? ''}
                    >
                      {['en', 'es', 'it', 'zh', 'ru', 'de', 'pt', 'fr'].map((language) => (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="language-text">Language code.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="title-name-input">Title</InputLabel>
                    <Input
                      id="title-name-input"
                      aria-describedby="title-name-input-helper-text"
                      value={formValues?.title ?? ''}
                      onChange={(e) => {
                        if (action === 'edit') {
                          const newTranslations = formValues?.translations?.filter(
                            (translation) => translation.language !== formValues.language
                          );
                          newTranslations.push({
                            language: formValues?.language,
                            title: e.target.value,
                            subtitle: formValues?.subtitle,
                            content: formValues?.content,
                          });
                          setFormValues({
                            ...formValues,
                            title: e.target.value,
                            translations: newTranslations,
                          });
                        } else {
                          setFormValues({
                            ...formValues,
                            title: e.target.value,
                          });
                        }
                      }}
                      fullWidth
                    />
                    <FormHelperText id="title-name-input-helper-text">Title.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={formValues?.language?.length > 0 ? false : true}
                    onClick={() => {
                      translate({
                        id: id,
                        data: {
                          from: formValues?.language,
                          title: formValues?.title,
                          subtitle: formValues?.subtitle,
                          content: formValues?.content,
                        },
                      });
                    }}
                  >
                    Translate
                  </Button>
                </Grid>
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="subtitle-name-input">Subtitle</InputLabel>
                    <Input
                      id="subtitle-name-input"
                      aria-describedby="subtitle-name-input-helper-text"
                      value={formValues?.subtitle ?? ''}
                      onChange={(e) => {
                        if (action === 'edit') {
                          const newTranslations = formValues?.translations?.filter(
                            (translation) => translation.language !== formValues.language
                          );
                          newTranslations.push({
                            language: formValues?.language,
                            title: formValues?.title,
                            subtitle: e.target.value,
                            content: formValues?.content,
                          });
                          setFormValues({
                            ...formValues,
                            subtitle: e.target.value,
                            translations: newTranslations,
                          });
                        } else {
                          setFormValues({
                            ...formValues,
                            subtitle: e.target.value,
                          });
                        }
                      }}
                      fullWidth
                    />
                    <FormHelperText id="subtitle-name-input-helper-text">Subtitle.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} minHeight={'550px'}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="content-name-input">Content</InputLabel>
                    <Editor text={text} onChangeText={setText} postId={id} />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {results.isLoading && (
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {translateResults.isLoading && (
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </>
  );
};
