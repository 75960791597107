import React from 'react';
import { selectUserToken } from 'services/authSlice';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = () => {
  const token = useSelector(selectUserToken);
  return token ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default ProtectedRoutes;
