import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
import { API_URL } from './env';

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${new Cookies().get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadAvatarUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `users/${id}/avatar`,
        method: 'POST',
        body: data,
      }),
    }),
    uploadRestaurantPhotos: builder.mutation({
      query: ({ data, id }) => ({
        url: `restaurants/${id}/images`,
        method: 'POST',
        body: data,
      }),
    }),
    uploadRestaurantMenu: builder.mutation({
      query: ({ data, id }) => ({
        url: `restaurants/${id}/menu`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteRestaurantMenu: builder.mutation({
      query: ({ id }) => ({
        url: `restaurants/${id}/menu`,
        method: 'DELETE',
      }),
    }),
    uploadPostPhotos: builder.mutation({
      query: ({ data, id }) => ({
        url: `posts/${id}/images`,
        method: 'POST',
        body: data,
      }),
    }),
    deletePostPhoto: builder.mutation({
      query: ({ id, imageid }) => ({
        url: `posts/${id}/images/${imageid}`,
        method: 'DELETE',
      }),
    }),
    uploadCountryPhoto: builder.mutation({
      query: ({ id, data }) => ({
        url: `countries/${id}/images`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteCountryPhoto: builder.mutation({
      query: ({ id, imageid }) => ({
        url: `countries/${id}/images/${imageid}`,
        method: 'DELETE',
      }),
    }),
    uploadRegionPhoto: builder.mutation({
      query: ({ id, data }) => ({
        url: `regions/${id}/images`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteRegionPhoto: builder.mutation({
      query: ({ id, imageid }) => ({
        url: `regions/${id}/images/${imageid}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUploadAvatarUserMutation,
  useUploadRestaurantPhotosMutation,
  useUploadPostPhotosMutation,
  useDeletePostPhotoMutation,
  useUploadCountryPhotoMutation,
  useDeleteCountryPhotoMutation,
  useUploadRegionPhotoMutation,
  useDeleteRegionPhotoMutation,
  useUploadRestaurantMenuMutation,
  useDeleteRestaurantMenuMutation,
} = uploadApi;
