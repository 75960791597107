import React from 'react';
import { Grid, Typography } from '@mui/material';

export const Content = ({ title, children }) => {
  return (
    <Grid container spacing={1} direction="column" style={{ marginTop: '0' }}>
      <Grid item md={4}>
        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item md={10}>
        <Grid container spacing={1} direction={'row'} justifyContent={'center'}>
          <Grid item md={11.5}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
