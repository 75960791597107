import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

export default function UserTable({ rows, onDelete }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="owners table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Middle Name</TableCell>
            <TableCell align="center">Last Name</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row?.user?.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.user?.email}
              </TableCell>
              <TableCell align="center">{row?.user?.profile?.name}</TableCell>
              <TableCell align="center">{row?.user?.profile?.middleName}</TableCell>
              <TableCell align="center">{row?.user?.profile?.lastName}</TableCell>
              <TableCell align="center">
                <Button href={`/admin/users/edit/${row?.user?.id}`}>View</Button>
                <Button onClick={() => onDelete(row?.user?.id)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
