import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
import { API_URL } from './env';

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${(new Cookies()).get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCountries: builder.query({
      query: () => {
        return { url: `countries` };
      },
    }),
    getCountry: builder.query({
      query: (id) => {
        return { url: `countries/${id}` };
      },
    }),
    createCountry: builder.mutation({
      query: ({ data }) => ({
        url: 'countries/',
        method: 'POST',
        body: { country: data },
      }),
    }),
    updateCountry: builder.mutation({
      query: ({ data, id }) => ({
        url: `countries/${id}`,
        method: 'PUT',
        body: { country: data },
      }),
    }),
    deleteCountry: builder.mutation({
      query: ({ id }) => ({
        url: `countries/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllCountriesQuery,
  useGetCountryQuery,
  useCreateCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countriesApi;
