import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined';

export const ToolBar = ({ addLink }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <div>
        <Button style={{ fontSize: '13px' }} label={'Add'} href={addLink}>
          <AddCircleOutlineOutlined
            color="primary"
            style={{ fontSize: '18px', marginRight: '8px', marginLeft: '-2px' }}
          />
          Add
        </Button>
      </div>
    </GridToolbarContainer>
  );
};
