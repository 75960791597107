import React from 'react';
import { selectUserToken } from 'services/authSlice';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PublicRoutes = () => {
  const token = useSelector(selectUserToken);
  return token ? <Navigate to="/admin/restaurants" /> : <Outlet />;
};

export default PublicRoutes;
