import React, { useEffect } from 'react';
import Geocode from 'react-geocode';
import {
  Input,
  InputLabel,
  Grid,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useDeleteRestaurantMenuMutation,
  useUploadRestaurantMenuMutation,
} from 'services/uploadApi';
import { Close, FileUpload } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';

const cuisineTypes = [
  { id: 1, name: 'italian' },
  { id: 2, name: 'spanish' },
  { id: 3, name: 'greek' },
  { id: 4, name: 'french' },
  { id: 5, name: 'portuguese' },
  { id: 6, name: 'israeli' },
  { id: 7, name: 'moroccan' },
  { id: 8, name: 'turkish' },
  { id: 13, name: 'argentinian' },
  { id: 14, name: 'mexico' },
  { id: 9, name: 'uruguayan' },
  { id: 10, name: 'mediterranean' },
  { id: 11, name: 'seafood' },
  { id: 12, name: 'steak house' },
  { id: 15, name: 'local cuisine' },
];

const solicitationState = ['pending', 'approved', 'rejected'];

export const RestaurantContent = ({ formValues, setFormValues }) => {
  const { id, action } = useParams();
  const [uploadMenu, results] = useUploadRestaurantMenuMutation();
  const [deleteMenu, deleteResults] = useDeleteRestaurantMenuMutation();
  const { enqueueSnackbar } = useSnackbar();

  const upload = (e, type) => {
    const newPhoto = new FormData();
    newPhoto.append(type, e.target.files[0]);
    uploadMenu({ id: id, data: newPhoto });
  };

  useEffect(() => {
    if (deleteResults.isSuccess) {
      setFormValues({
        ...formValues,
        menu: null,
      });
      enqueueSnackbar(`Successfully removed`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }

    if (deleteResults.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [deleteResults, enqueueSnackbar]);

  useEffect(() => {
    if (results.isSuccess) {
      setFormValues({
        ...formValues,
        menu: results.data?.restaurant?.menu,
      });
      enqueueSnackbar(`Successfully uploaded`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }

    if (results.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results, enqueueSnackbar]);

  Geocode.setApiKey('AIzaSyCtKjiLmtKQ-R79xiAllwqrv0KK2sjtpF8');
  useEffect(() => {
    if (formValues?.address?.address && formValues?.address?.city && formValues?.address?.country)
      Geocode.fromAddress(
        `${formValues?.address?.address}, ${formValues?.address?.city} ${formValues?.address?.country}`
      ).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (lat != formValues?.geolocation?.latitude || lng != formValues?.geolocation?.longitude)
            setFormValues({
              ...formValues,
              geolocation: { latitude: lat + '', longitude: lng + '' },
            });
        },
        (error) => {
          console.error(error);
        }
      );
    // eslint-disable-next-line
  }, [formValues]);
  return (
    <>
      <Grid container direction="row" spacing={2} mt={1}>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="General" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid
                container
                justifyContent="center"
                direction="row"
                spacing={4}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="restaurant-name-input">Name</InputLabel>
                    <Input
                      id="restaurant-name-input"
                      aria-describedby="restaurant-name-input-helper-text"
                      value={formValues?.name ?? ''}
                      onChange={(e) => setFormValues({ ...{ name: e.target.value } })}
                      fullWidth
                    />
                    <FormHelperText id="restaurant-name-input-helper-text">
                      Restarant name.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="cuisine-type-name-select">Cuisine type</InputLabel>
                    <Select
                      variant="standard"
                      id="cuisine-type-select"
                      onChange={(e) =>
                        setFormValues({ ...formValues, cuisine: { id: e.target.value } })
                      }
                      value={formValues?.cuisine?.id ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {cuisineTypes.map((cuisine) => (
                        <MenuItem
                          key={cuisine.id}
                          value={cuisine.id}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {cuisine.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="cuisine-type-text">Restaurant cuisine type.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="state-type-name-select">Solicitation State</InputLabel>
                    <Select
                      variant="standard"
                      id="state-type-select"
                      onChange={(e) =>
                        setFormValues({ ...formValues, solicitationState: e.target.value })
                      }
                      value={formValues?.solicitationState ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {solicitationState.map((state) => (
                        <MenuItem key={state} value={state} sx={{ textTransform: 'capitalize' }}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="state-type-text">
                      Restaurant solicitation state.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              mediterraneanFood: e.target.checked,
                            })
                          }
                          checked={formValues?.mediterraneanFood ?? false}
                        />
                      }
                      sx={{ textTransform: 'capitalize' }}
                      label="Serves mediterranean food"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Contact" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-code-input">Country Code</InputLabel>
                    <Input
                      id="country-code-input"
                      aria-describedby="country-code-text"
                      value={formValues?.contact?.phone?.countryCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          contact: {
                            ...formValues.contact,
                            phone: { ...formValues.contact?.phone, countryCode: e.target.value },
                          },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="country-code-input-help">Country code.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="area-code-input">Area Code</InputLabel>
                    <Input
                      id="area-code-input"
                      aria-describedby="area-code-input-text"
                      value={formValues?.contact?.phone?.areaCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          contact: {
                            ...formValues.contact,
                            phone: { ...formValues.contact?.phone, areaCode: e.target.value },
                          },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="area-code-input-help">Area code.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="phone-number-input">Phone Number</InputLabel>
                    <Input
                      id="phone-number-input"
                      aria-describedby="phone-number-input-text"
                      value={formValues?.contact?.phone?.number ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          contact: {
                            ...formValues.contact,
                            phone: { ...formValues.contact?.phone, number: e.target.value },
                          },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="phone-number-input-help">
                      Restaurant phone number.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="email-input">Email</InputLabel>
                    <Input
                      id="email-input"
                      aria-describedby="email-input-text"
                      value={formValues?.contact?.email ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          contact: {
                            ...formValues.contact,
                            email: e.target.value,
                          },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="email-input-help">Restaurant email.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="website-input">Website</InputLabel>
                    <Input
                      id="website-input"
                      aria-describedby="website-input-text"
                      value={formValues?.contact?.website ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          contact: {
                            ...formValues.contact,
                            website: e.target.value,
                          },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="website-input-help">Restaurant website.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Address" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-name-shipping-input">Country name</InputLabel>
                    <Input
                      id="country-name-shipping-input"
                      aria-describedby="country-name-shipping-input-text"
                      value={formValues?.address?.country ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, country: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="country-name-shipping-input-help">
                      Shipping country.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="region-name-shipping-input">Region name</InputLabel>
                    <Input
                      id="region-name-shipping-input"
                      aria-describedby="region-name-shipping-input-text"
                      value={formValues?.address?.region ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, region: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="region-name-shipping-input-help">
                      Shipping region.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="city-name-shipping-input">City name</InputLabel>
                    <Input
                      id="city-name-shipping-input"
                      aria-describedby="city-name-shipping-input-text"
                      value={formValues?.address?.city ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, city: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="city-name-shipping-input-help">
                      Shipping city.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="postal-code-name-shipping-input">Postal Code</InputLabel>
                    <Input
                      id="postal-code-name-shipping-input"
                      aria-describedby="postal-code-name-shipping-input-text"
                      value={formValues?.address?.postalCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, postalCode: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="postal-code-name-shipping-input-help">
                      Shipping postal code.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="address-shipping-input">Address</InputLabel>
                    <Input
                      id="address-shipping-input"
                      aria-describedby="address-shipping-input-text"
                      value={formValues?.address?.address ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, address: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="address-shipping-input-help">Address name.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Geolocation" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="latitude-input">Latitude</InputLabel>
                    <Input
                      id="latitude-input"
                      aria-describedby="latitude-input-text"
                      value={formValues?.geolocation?.latitude ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          geolocation: { ...formValues.geolocation, latitude: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="latitude-input-help">Latitude.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="longitude-input">Longitude</InputLabel>
                    <Input
                      id="longitude-input"
                      aria-describedby="longitude-input-text"
                      value={formValues?.geolocation?.longitude ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          geolocation: { ...formValues.geolocation, longitude: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="longitude-input-help">Longitude.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Menu" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs>
                  <Button href={formValues?.menu?.url} disabled={formValues?.menu ? false : true}>
                    View
                  </Button>
                  <label>
                    {action === 'edit' && (
                      <Input
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          upload(e, 'menu');
                        }}
                      />
                    )}
                    <IconButton color="primary" aria-label="upload menu" component="span">
                      <FileUpload />
                    </IconButton>
                  </label>
                  {formValues?.menu?.url && (
                    <IconButton
                      color="primary"
                      aria-label="upload menu"
                      component="span"
                      onClick={() => {
                        deleteMenu({ id: id });
                      }}
                    >
                      <Close />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Video" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <FormControl fullWidth>
                  <InputLabel htmlFor="video-url-input">Video URL</InputLabel>
                  <Input
                    id="video-url-input"
                    aria-describedby="video-url-input-text"
                    value={formValues?.videoUrl ?? ''}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        videoUrl: e.target.value,
                      })
                    }
                    fullWidth
                  />
                  <FormHelperText id="video-url-input-help">Video URL.</FormHelperText>
                </FormControl>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {results?.isLoading && (
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </>
  );
};
