import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
import { API_URL } from './env';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${(new Cookies()).get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => {
        return { url: `users` };
      },
    }),
    getUser: builder.query({
      query: (id) => {
        return { url: `users/${id}` };
      },
    }),
    getSelf: builder.query({
      query: () => {
        return { url: `users/self` };
      },
    }),
    createUser: builder.mutation({
      query: ({ data }) => ({
        url: 'users/',
        method: 'POST',
        body: { user: data },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: { user: data },
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
    }),
    getAllOwners: builder.query({
      query: () => {
        return { url: `users/owners` };
      },
    }),
    getAllEmployees: builder.query({
      query: () => {
        return { url: `users/employees` };
      },
    }),
    getAllChefs: builder.query({
      query: () => {
        return { url: `users/chefs` };
      },
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetAllOwnersQuery,
  useGetAllEmployeesQuery,
  useGetAllChefsQuery,
  useGetSelfQuery
} = usersApi;
