import { Button } from '@mui/material';
import React from 'react';

export const PostColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'title',
    headerName: 'Title',
    description: 'Title of the post',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => {
      if (params.row.translations?.length > 0) {
        for (const translation of params.row.translations) {
          if (translation.language === 'en') {
            return translation.title;
          }
        }
      }
    },
  },
  {
    field: 'subtitle',
    headerName: 'Subtitle',
    description: 'Subtitle of the post',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => {
      if (params.row.translations?.length > 0) {
        for (const translation of params.row.translations) {
          if (translation.language === 'en') {
            return translation.subtitle;
          }
        }
      }
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    description: 'Category of the post',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.category?.name,
  },
  {
    field: 'country',
    headerName: 'Country',
    description: 'Country of the post',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.country?.name,
  },
  {
    field: 'state',
    headerName: 'State',
    description: 'State of the post',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.status,
    renderCell: (params) => {
      if (params.row?.status?.toLowerCase() === 'draft') {
        return (
          <div
            style={{
              marginLeft: '10%',
              display: 'inline-flex',
              position: 'relative',
              justifyContent: 'center',
              backgroundColor: '#ff0000',
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
              padding: '4px 10px',
              borderRadius: '4px',
              alignItems: 'center',
              color: '#fff',
              maxHeight: '30px',
            }}
          >
            <strong style={{ justifyContent: 'center' }}>Draft</strong>
          </div>
        );
      }
      if (params.row?.status?.toLowerCase() === 'published') {
        return (
          <div
            style={{
              marginLeft: '10%',
              display: 'inline-flex',
              position: 'relative',
              justifyContent: 'center',
              backgroundColor: '#008450',
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
              padding: '4px 10px',
              borderRadius: '4px',
              alignItems: 'center',
              color: '#fff',
              maxHeight: '30px',
            }}
          >
            <strong style={{ justifyContent: 'center' }}>Published</strong>
          </div>
        );
      }
      return (
        <div
          style={{
            marginLeft: '10%',
            display: 'inline-flex',
            position: 'relative',
            justifyContent: 'center',
            backgroundColor: '#EFB700',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            padding: '4px 10px',
            borderRadius: '4px',
            alignItems: 'center',
            color: '#fff',
            maxHeight: '30px',
          }}
        >
          <strong style={{ justifyContent: 'center' }}>Pending</strong>
        </div>
      );
    },
  },
  {
    field: 'details',
    headerName: 'Details',
    description: 'Link to the post details',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      return (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: '10%' }}
            href={`/admin/posts/edit/${params.id}`}
          >
            Edit
          </Button>
        </strong>
      );
    },
  },
];
