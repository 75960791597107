import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from './env';
import Cookies from 'universal-cookie'

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${(new Cookies()).get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => {
        return { url: `categories` };
      },
    }),
    getCategory: builder.query({
      query: (id) => {
        return { url: `categories/${id}` };
      },
    }),
    createCategory: builder.mutation({
      query: ({ data }) => ({
        url: 'categories/',
        method: 'POST',
        body: { category: data },
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ data, id }) => ({
        url: `categories/${id}`,
        method: 'PUT',
        body: { category: data },
      }),
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        url: `categories/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
