import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Accordion from './Accordion';
import { QRCodeSVG } from 'qrcode.react';
import logo from 'assets/logo.svg';

export function QrPanel({ data }) {
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={6} p={2}>
        <Card sx={{ minHeight: '80vh', backgroundColor: '#f5f5f5' }}>
          <CardContent>
            <Grid container direction="columns" justifyContent="space-between" spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
                  qr code
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <QRCodeSVG
                  value={data?.contact?.website}
                  imageSettings={{ src: logo, width: 50, height: 50 }}
                  size={300}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} p={2} sx={{display: 'none'}}>
        <Accordion label="English" />
        <Accordion label="Spanish" />
        <Accordion label="Italian" />
        <Accordion label="French" />
        <Accordion label="German" />
      </Grid>
    </Grid>
  );
}
