import { PhotoCamera } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteCountryPhotoMutation, useUploadCountryPhotoMutation } from 'services/uploadApi';

export const CountryContent = ({ formValues, setFormValues }) => {
  const { id, action } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadPhoto, results] = useUploadCountryPhotoMutation();
  const [deletePhoto, deleteResults] = useDeleteCountryPhotoMutation();

  const upload = (e, type) => {
    const newPhoto = new FormData();
    newPhoto.append(type, e.target.files[0]);
    uploadPhoto({ id: id, data: newPhoto });
  };

  useEffect(() => {
    if (deleteResults.isSuccess) {
      setFormValues({
        ...formValues,
        imageCollection: deleteResults.data?.country?.imageCollection,
      });
      enqueueSnackbar(`Successfully removed`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }

    if (deleteResults.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [deleteResults, enqueueSnackbar]);

  useEffect(() => {
    if (results.isSuccess) {
      setFormValues({
        ...formValues,
        imageCollection: results.data?.country?.imageCollection,
      });
      enqueueSnackbar(`Successfully uploaded`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }

    if (results.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results, enqueueSnackbar]);

  return (
    <>
      <Grid container direction="row" spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="General" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container justifyContent="center">
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-name-input">Name</InputLabel>
                    <Input
                      id="country-name-input"
                      aria-describedby="country-name-input-helper-text"
                      value={formValues?.name ?? ''}
                      onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                      fullWidth
                    />
                    <FormHelperText id="country-name-input-helper-text">
                      Country name.
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Images" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <Typography variant="h6" gutterBottom component={'span'}>
                    Cover
                  </Typography>
                  <label>
                    {action === 'edit' && (
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          upload(e, 'cover');
                        }}
                      />
                    )}
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Grid>
                <Grid item xs={10}>
                  <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
                    <ImageListItem sx={{ boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}>
                      <img
                        src={formValues?.imageCollection?.cover?.url}
                        srcSet={formValues?.imageCollection?.cover?.url}
                        alt={formValues?.imageCollection?.cover?.name}
                      />
                      <ImageListItemBar
                        actionIcon={
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${formValues?.imageCollection?.cover?.name}`}
                            onClick={() =>
                              deletePhoto({
                                id: id,
                                imageid: formValues?.imageCollection?.cover?.id,
                              })
                            } //TODO: Delete function
                          >
                            <ClearIcon />
                          </IconButton>
                        }
                        title={`Cover image`}
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" gutterBottom component={'span'}>
                    Flag
                  </Typography>
                  <label>
                    {action === 'edit' && (
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          upload(e, 'flag');
                        }}
                      />
                    )}
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Grid>
                <Grid item xs={10}>
                  <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
                    <ImageListItem sx={{ boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}>
                      <img
                        src={formValues?.imageCollection?.flag?.url}
                        srcSet={formValues?.imageCollection?.flag?.url}
                        alt={formValues?.imageCollection?.flag?.name}
                      />
                      <ImageListItemBar
                        actionIcon={
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${formValues?.imageCollection?.flag?.name}`}
                            onClick={() =>
                              deletePhoto({
                                id: id,
                                imageid: formValues?.imageCollection?.flag?.id,
                              })
                            } //TODO: Delete function
                          >
                            <ClearIcon />
                          </IconButton>
                        }
                        title={`Flag image`}
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" gutterBottom component={'span'}>
                    Regions
                  </Typography>
                  <label>
                    {action === 'edit' && (
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          upload(e, 'region');
                        }}
                      />
                    )}
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Grid>
                <Grid item xs={10}>
                  <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
                    <ImageListItem sx={{ boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' }}>
                      <img
                        src={formValues?.imageCollection?.region?.url}
                        srcSet={formValues?.imageCollection?.region?.url}
                        alt={formValues?.imageCollection?.region?.name}
                      />
                      <ImageListItemBar
                        actionIcon={
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${formValues?.imageCollection?.region?.name}`}
                            onClick={() =>
                              deletePhoto({
                                id: id,
                                imageid: formValues?.imageCollection?.region?.id,
                              })
                            } //TODO: Delete function
                          >
                            <ClearIcon />
                          </IconButton>
                        }
                        title={`Region image`}
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {results?.isLoading && (
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </>
  );
};
