import { Navbar } from 'app/Navbar';
import { MainView } from 'features/create-update-view/MainView';
import { useGetCategoryQuery } from 'services/categoriesApi';
import { useGetCountryQuery } from 'services/countriesApi';
import { useGetPostQuery } from 'services/postsApi';
import { useGetRegionQuery } from 'services/regionsApi';
import { useGetRestaurantQuery } from 'services/restaurantsApi';
import { useGetUserQuery } from 'services/usersApi';

export const CreateUpdateViewRoutes = [
  {
    property: 'restaurants',
    element: (
      <>
        <Navbar />
        <MainView useGetQueryFunction={useGetRestaurantQuery} property={'restaurants'} />
      </>
    ),
  },
  {
    property: 'users',
    element: (
      <>
        <Navbar />
        <MainView useGetQueryFunction={useGetUserQuery} property={'users'} />
      </>
    ),
  },
  {
    property: 'categories',
    element: (
      <>
        <Navbar />
        <MainView useGetQueryFunction={useGetCategoryQuery} property={'categories'} />
      </>
    ),
  },
  {
    property: 'countries',
    element: (
      <>
        <Navbar />
        <MainView useGetQueryFunction={useGetCountryQuery} property={'countries'} />
      </>
    ),
  },
  {
    property: 'regions',
    element: (
      <>
        <Navbar />
        <MainView useGetQueryFunction={useGetRegionQuery} property={'regions'} />
      </>
    ),
  },
  {
    property: 'posts',
    element: (
      <>
        <Navbar />
        <MainView useGetQueryFunction={useGetPostQuery} property={'posts'} />
      </>
    ),
  },
];
