import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
import { API_URL } from './env';

export const regionsApi = createApi({
  reducerPath: 'regionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${(new Cookies()).get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllRegions: builder.query({
      query: () => {
        return { url: `regions` };
      },
    }),
    getRegion: builder.query({
      query: (id) => {
        return { url: `regions/${id}` };
      },
    }),
    createRegion: builder.mutation({
      query: ({ data }) => ({
        url: 'regions/',
        method: 'POST',
        body: { region: data },
      }),
    }),
    updateRegion: builder.mutation({
      query: ({ data, id }) => ({
        url: `regions/${id}`,
        method: 'PUT',
        body: { region: data },
      }),
    }),
    deleteRegion: builder.mutation({
      query: ({ id }) => ({
        url: `regions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const selectAllRegions = (regions) => regions;

export const {
  useGetAllRegionsQuery,
  useGetRegionQuery,
  useCreateRegionMutation,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
} = regionsApi;
