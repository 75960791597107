import { Button } from '@mui/material';
import React from 'react';

export const RestaurantColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name of the restaurant',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.name,
  },
  {
    field: 'address',
    headerName: 'Address',
    description: 'Address of the restaurant',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.address,
  },
  {
    field: 'city',
    headerName: 'City',
    description: 'City of the restaurant',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.city,
  },
  {
    field: 'postalCode',
    headerName: 'Postal Code',
    description: 'Postal code of the restaurant',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.postalCode,
  },
  {
    field: 'country',
    headerName: 'Country',
    description: 'Country of the restaurant',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.address?.country,
  },
  {
    field: 'state',
    headerName: 'Solicitation state',
    description: 'Solicitation state of the restaurant',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.solicitationState,
    renderCell: (params) => {
      if (params.row?.solicitationState?.toLowerCase() === 'rejected') {
        return (
          <div
            style={{
              marginLeft: '10%',
              display: 'inline-flex',
              position: 'relative',
              justifyContent: 'center',
              backgroundColor: '#B81D13',
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
              padding: '4px 10px',
              borderRadius: '4px',
              alignItems: 'center',
              color: '#fff',
              maxHeight: '30px',
            }}
          >
            <strong style={{ justifyContent: 'center' }}>Rejected</strong>
          </div>
        );
      }
      if (params.row?.solicitationState?.toLowerCase() === 'approved') {
        return (
          <div
            style={{
              marginLeft: '10%',
              display: 'inline-flex',
              position: 'relative',
              justifyContent: 'center',
              backgroundColor: '#008450',
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
              padding: '4px 10px',
              borderRadius: '4px',
              alignItems: 'center',
              color: '#fff',
              maxHeight: '30px',
            }}
          >
            <strong style={{ justifyContent: 'center' }}>Approved</strong>
          </div>
        );
      }
      return (
        <div
          style={{
            marginLeft: '10%',
            display: 'inline-flex',
            position: 'relative',
            justifyContent: 'center',
            backgroundColor: '#EFB700',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            padding: '4px 10px',
            borderRadius: '4px',
            alignItems: 'center',
            color: '#fff',
            maxHeight: '30px',
          }}
        >
          <strong style={{ justifyContent: 'center' }}>Pending</strong>
        </div>
      );
    },
  },
  {
    field: 'details',
    headerName: 'Details',
    description: 'Link to the restaurant details',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      return (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: '10%' }}
            href={`/admin/restaurants/edit/${params.id}`}
          >
            Edit
          </Button>
        </strong>
      );
    },
  },
];
