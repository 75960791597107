import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ToolBar } from './toolbar/Toolbar';
import { useSnackbar } from 'notistack';

export function Table({ property, columns, useQueryFunction, selectFunction }) {
  const [rows, setRows] = useState([]);
  const { data, isSuccess, isError, error } = useQueryFunction();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
    isSuccess ? setRows(data[property]) : setRows([]);
  }, [data, isSuccess, error, selectFunction, property]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(`Successfully obtained data`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    if (isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [isError, isSuccess, enqueueSnackbar]);

  return (
    <div style={{ height: 'calc(100vh - 150px)', width: '100%' }}>
      <DataGrid
        components={{
          Toolbar: () => <ToolBar addLink={`/admin/${property}/new`} />,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection
        autoPageSize
        rowHeight={70}
        headerHeight={65}
        page={page}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
}
