import React, { useEffect } from 'react';
import {
  Input,
  InputLabel,
  Grid,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  FormControl,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Avatar,
  Button,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { useUploadAvatarUserMutation } from 'services/uploadApi';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useSendVerificationTokenMutation } from 'services/authApi';

export const UserContent = ({ formValues, setFormValues }) => {
  const { action } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadAvatar, results] = useUploadAvatarUserMutation();
  const [sendVerification, verificationResults] = useSendVerificationTokenMutation();
  const rolesData = [
    { id: 1, name: 'admin' },
    { id: 2, name: 'owner' },
    { id: 3, name: 'chef' },
    { id: 6, name: 'business' },
    { id: 5, name: 'customer' },
  ];

  useEffect(() => {
    if (results.isSuccess) {
      enqueueSnackbar(`Successfully uploaded`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setFormValues({
        ...formValues,
        profile: { ...formValues.profile, avatar: results.data?.file },
      });
    }
    if (results.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    if (verificationResults.isSuccess) {
      enqueueSnackbar('Successfully sent', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    if (verificationResults.isError) {
      enqueueSnackbar(`${verificationResults?.error?.data?.error?.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results, enqueueSnackbar, verificationResults]);

  const upload = (e) => {
    const newAvatar = new FormData();
    newAvatar.append('image', e.target.files[0]);
    uploadAvatar({ id: formValues.id, data: newAvatar });
  };
  return (
    <>
      <Grid container direction="row" spacing={2} mt={1}>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Account info" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                spacing={4}
                alignItems="center"
              >
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="role-select">Role</InputLabel>
                    <Select
                      variant="standard"
                      id="role-select"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          role: { ...formValues.role, id: e.target.value },
                        })
                      }
                      value={formValues?.role?.id ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {rolesData.map((role) => (
                        <MenuItem
                          key={role.id}
                          value={role.id}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="role-select-help">Role name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="email-input">Email</InputLabel>
                    <Input
                      id="email-input"
                      aria-describedby="email-input-helper"
                      value={formValues?.email ?? ''}
                      onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                      fullWidth
                    />
                    <FormHelperText id="email-input-text-help">User email.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    my={1}
                    bgcolor={formValues.isVerified ? 'green' : 'red'}
                    borderRadius={2}
                  >
                    <Typography color={'#fff'} variant="text" sx={{ textTransform: 'capitalize' }}>
                      {formValues.isVerified ? 'confirmed' : 'not confirmed'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="password-input">Password</InputLabel>
                    <Input
                      id="password-input"
                      aria-describedby="password-input-helper"
                      value={formValues?.password ?? ''}
                      onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                      fullWidth
                      disabled={action === 'edit' ? true : false}
                    />
                    <FormHelperText id="password-input-text-help">User password.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="password-confirmation-input">
                      Password confirmation
                    </InputLabel>
                    <Input
                      id="password-confirmation-input"
                      aria-describedby="password-confirmation-input-helper"
                      value={formValues?.passwordConfirmation ?? ''}
                      onChange={(e) =>
                        setFormValues({ ...formValues, passwordConfirmation: e.target.value })
                      }
                      fullWidth
                      disabled={action === 'edit' ? true : false}
                    />
                    <FormHelperText id="password-confirmation-input-text-help">
                      User password confirmation.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      sendVerification({ data: { email: formValues?.email } });
                    }}
                  >
                    Verify email
                  </Button>
                </Grid>
                {formValues?.role?.name === 'business' && (
                  <>
                    <Grid xs={12} sm={6}>
                      <FormControl sx={{ width: { xs: '85%', sm: '75%' } }}>
                        <FormHelperText id="business-type-text">BusinessType</FormHelperText>
                        <Select
                          id="business-type-select"
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              profile: {
                                ...formValues?.profile,
                                businessType: e.target.value,
                              },
                            });
                          }}
                          value={formValues?.profile?.businessType ?? ''}
                        >
                          {['sommelier', 'wineMakingCompany', 'wineDistributor', 'beerCompany'].map(
                            (business) => (
                              <MenuItem
                                key={business}
                                value={business}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {business}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <FormControl sx={{ width: { xs: '85%', sm: '75%' } }}>
                        <FormHelperText id="business-name-input">Business Name</FormHelperText>
                        <Input
                          fullWidth
                          id="business-name-input"
                          aria-describedby="business-name-input-helper-text"
                          value={formValues?.profile?.businessName ?? ''}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              profile: {
                                ...formValues?.profile,
                                businessName: e.target.value,
                              },
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} />
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Avatar info" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} maxHeight="100%">
                  <Avatar
                    src={formValues?.profile?.avatar?.url}
                    sx={{ width: 100, height: 100, border: '1px solid #1876d1' }}
                  />
                </Grid>
                <Grid item xs={2}>
                  {action === 'edit' && (
                    <InputLabel htmlFor="avatar-button-file">
                      <Input
                        style={{ display: 'none' }}
                        id="avatar-button-file"
                        name="avatar-button-file"
                        type="file"
                        onChange={(e) => {
                          upload(e);
                        }}
                      />
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </InputLabel>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Profile Info" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="treatment-select">Treatment</InputLabel>
                    <Select
                      variant="standard"
                      id="treatment-select"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          profile: { ...formValues.profile, treatment: e.target.value },
                        })
                      }
                      value={formValues?.profile?.treatment ?? ''}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {['sr', 'mr', 'srs'].map((treatment) => (
                        <MenuItem
                          key={treatment}
                          value={treatment}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {treatment}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="treatment-select-help">User treatment.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="name-input">Name</InputLabel>
                    <Input
                      id="name-input"
                      aria-describedby="name-input-text"
                      value={formValues?.profile?.name ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          profile: { ...formValues.profile, name: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="name-input-help">User name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="middle-name-input">Middle Name</InputLabel>
                    <Input
                      id="middle-name-input"
                      aria-describedby="middle-name-input-text"
                      value={formValues?.profile?.middleName ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          profile: { ...formValues.profile, middleName: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="middle-name-input-help">User middle name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="last-name-input">Last Name</InputLabel>
                    <Input
                      id="last-name-input"
                      aria-describedby="last-name-input-text"
                      value={formValues?.profile?.lastName ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          profile: { ...formValues.profile, lastName: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="last-name-input-help">User last name.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Contact" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-code-input">Country Code</InputLabel>
                    <Input
                      id="country-code-input"
                      aria-describedby="country-code-text"
                      value={formValues?.phone?.countryCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          phone: { ...formValues.phone, countryCode: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="country-code-input-help">
                      Phone country code.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="area-code-input">Area Code</InputLabel>
                    <Input
                      id="area-code-input"
                      aria-describedby="area-code-input-text"
                      value={formValues?.phone?.areaCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          phone: { ...formValues.phone, areaCode: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="area-code-input-help">User area code.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="phone-number-input">Phone Number</InputLabel>
                    <Input
                      id="phone-number-input"
                      aria-describedby="phone-number-input-text"
                      value={formValues?.phone?.number ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          phone: { ...formValues.phone, number: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="phone-number-input-help">User phone number.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Shipping Info" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-name-shipping-input">Country name</InputLabel>
                    <Input
                      id="country-name-shipping-input"
                      aria-describedby="country-name-shipping-input-text"
                      value={formValues?.address?.country ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, country: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="country-name-shipping-input-help">
                      Shipping country.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="region-name-shipping-input">Region name</InputLabel>
                    <Input
                      id="region-name-shipping-input"
                      aria-describedby="region-name-shipping-input-text"
                      value={formValues?.address?.region ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, region: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="region-name-shipping-input-help">
                      Shipping region.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="city-name-shipping-input">City name</InputLabel>
                    <Input
                      id="city-name-shipping-input"
                      aria-describedby="city-name-shipping-input-text"
                      value={formValues?.address?.city ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, city: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="city-name-shipping-input-help">
                      Shipping city.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="postal-code-name-shipping-input">Postal Code</InputLabel>
                    <Input
                      id="postal-code-name-shipping-input"
                      aria-describedby="postal-code-name-shipping-input-text"
                      value={formValues?.address?.postalCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, postalCode: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="postal-code-name-shipping-input-help">
                      Shipping postal code.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="address-shipping-input">Address</InputLabel>
                    <Input
                      id="address-shipping-input"
                      aria-describedby="address-shipping-input-text"
                      value={formValues?.address?.address ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          address: { ...formValues.address, address: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="address-shipping-input-help">Address name.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Billing Info" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country-name-billing-input">Country name</InputLabel>
                    <Input
                      id="country-name-billing-input"
                      aria-describedby="country-name-billing-input-text"
                      value={formValues?.billingAddress?.country ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          billingAddress: { ...formValues.billingAddress, country: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="country-name-billing-input-help">
                      Billing country.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="region-name-billing-input">Region name</InputLabel>
                    <Input
                      id="region-name-billing-input"
                      aria-describedby="region-name-billing-input-text"
                      value={formValues?.billingAddress?.region ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          billingAddress: { ...formValues.billingAddress, region: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="region-name-billing-input-help">
                      Billing region.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="city-name-billing-input">City name</InputLabel>
                    <Input
                      id="city-name-billing-input"
                      aria-describedby="city-name-billing-input-text"
                      value={formValues?.billingAddress?.city ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          billingAddress: { ...formValues.billingAddress, city: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="city-name-billing-input-help">Billing city.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="postal-code-name-billing-input">Postal Code</InputLabel>
                    <Input
                      id="postal-code-name-billing-input"
                      aria-describedby="postal-code-name-billing-input-text"
                      value={formValues?.billingAddress?.postalCode ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          billingAddress: {
                            ...formValues.billingAddress,
                            postalCode: e.target.value,
                          },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="postal-code-name-billing-input-help">
                      Billing postal code.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="address-billing-input">Address</InputLabel>
                    <Input
                      id="address-billing-input"
                      aria-describedby="address-billing-input-text"
                      value={formValues?.billingAddress?.address ?? ''}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          billingAddress: { ...formValues.billingAddress, address: e.target.value },
                        })
                      }
                      fullWidth
                    />
                    <FormHelperText id="address-billing-input-help">Address name.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
