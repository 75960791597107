import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import React from 'react';

export const CategoryContent = ({ formValues, setFormValues }) => {
  return (
    <>
      <Grid container direction="row" spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="General info" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container justifyContent="center">
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="category-name-input">Name</InputLabel>
                    <Input
                      id="category-name-input"
                      aria-describedby="category-name-input-helper-text"
                      value={formValues?.name ?? ''}
                      onChange={(e) => setFormValues({ ...{ name: e.target.value } })}
                      fullWidth
                    />
                    <FormHelperText id="category-name-input-helper-text">
                      Category name.
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
