import {
  FormControl,
  FormHelperText,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Autocomplete,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import UserTable from 'features/create-update-view/table/UserTable';
import React, { useEffect, useState } from 'react';
import {
  useAddRestaurantChefMutation,
  useDeleteRestaurantChefMutation,
  useGetRestaurantQuery,
  useUpdateRestaurantMutation,
} from 'services/restaurantsApi';
import { useGetAllChefsQuery, useGetAllOwnersQuery } from 'services/usersApi';
import { useSnackbar } from 'notistack';
export function PeoplePanel({ data_ }) {
  const [value, setValue] = React.useState('');
  const [chefValue, setChefValue] = React.useState('');
  const [lastOwner, setLastOwner] = React.useState({});
  const [inputValue, setInputValue] = React.useState('');
  const [inputChefValue, setChefInputValue] = React.useState('');
  const [formValues, setFormValues] = useState({});
  const [updateRestaurant, results] = useUpdateRestaurantMutation();
  const { data: restaurantData, refetch } = useGetRestaurantQuery(data_?.id);
  const { data } = useGetAllOwnersQuery();
  const { data: chefsData } = useGetAllChefsQuery();
  const [addChef, addChefResults] = useAddRestaurantChefMutation();
  const [deleteChef, deleteChefResults] = useDeleteRestaurantChefMutation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (results.isSuccess || addChefResults.isSuccess || deleteChefResults.isSuccess) {
      setLastOwner(value);
      enqueueSnackbar(`Successfully updated`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      refetch();
    }

    if (results.isError || addChefResults.isError || deleteChefResults.isError) {
      enqueueSnackbar('We could not perform the operation at the moment', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results, enqueueSnackbar, addChefResults, deleteChefResults]);

  useEffect(() => {
    if (restaurantData?.restaurant?.owner?.id) {
      const owner = data?.users?.filter(
        (user) => user?.id === restaurantData?.restaurant?.owner?.id
      )[0];
      setValue(owner);
      setLastOwner(owner);
    }
  }, [restaurantData, data]);

  const onDeleteRestaurantChef = (chefid) => {
    deleteChef({ id: data_.id, chefid: chefid });
    refetch();
  };

  return (
    <>
      <Grid container direction="row" spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Ownership" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      value={value ?? ''}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                        setFormValues({ ...formValues, owner: { id: newValue?.id ?? null } });
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id="owner-autocomplete"
                      options={data?.users ?? []}
                      getOptionLabel={(option) =>
                        option.profile
                          ? `${option.profile?.name} ${option.profile?.middleName} ${option.profile?.lastName}`
                          : ''
                      }
                      sx={{ textTransform: 'capitalize' }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <FormHelperText id="owner-text">User name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  {value !== lastOwner && (
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        updateRestaurant({ id: data_?.id, data: formValues });
                      }}
                      sx={{ minHeight: '100%' }}
                    >
                      Change
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ textTransform: 'capitalize' }}>owner details</Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography>
                      Name:{' '}
                      {`${lastOwner?.profile?.name ?? ''} ${lastOwner?.profile?.middleName ?? ''} ${
                        lastOwner?.profile?.lastName ?? ''
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Email: {`${lastOwner?.email ?? ''}`}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" href={`/admin/users/edit/${lastOwner?.id}`}>
                    View
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%' }}>
            <CardHeader title="Chefs" sx={{ backgroundColor: '#f5f5f5' }} />
            <CardContent>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      value={chefValue ?? ''}
                      onChange={(event, newValue) => {
                        setChefValue(newValue);
                      }}
                      inputValue={inputChefValue}
                      onInputChange={(event, newInputValue) => {
                        setChefInputValue(newInputValue);
                      }}
                      id="owner-autocomplete"
                      options={chefsData?.users ?? []}
                      getOptionLabel={(option) =>
                        option.profile
                          ? `${option.profile?.name} ${option.profile?.middleName} ${option.profile?.lastName}`
                          : ''
                      }
                      sx={{ textTransform: 'capitalize' }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <FormHelperText id="owner-text">User name.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  {chefValue && (
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        addChef({ id: data_?.id, chefid: chefValue?.id });
                        refetch();
                      }}
                      sx={{ minHeight: '100%' }}
                    >
                      Add
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <UserTable
                    rows={restaurantData?.restaurant?.chefs ?? []}
                    onDelete={onDeleteRestaurantChef}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
