import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
import { API_URL } from './env';

export const restaurantsApi = createApi({
  reducerPath: 'restaurantApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${(new Cookies()).get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllRestaurants: builder.query({
      query: () => {
        return { url: `restaurants` };
      },
    }),
    getRestaurant: builder.query({
      query: (id) => {
        return { url: `restaurants/${id}` };
      },
    }),
    createRestaurant: builder.mutation({
      query: ({ data }) => ({
        url: 'restaurants/',
        method: 'POST',
        body: { restaurant: data },
      }),
    }),
    updateRestaurant: builder.mutation({
      query: ({ data, id }) => ({
        url: `restaurants/${id}`,
        method: 'PUT',
        body: { restaurant: data },
      }),
    }),
    deleteRestaurant: builder.mutation({
      query: ({ id }) => ({
        url: `restaurants/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteRestaurantImage: builder.mutation({
      query: ({ id, imageid }) => ({
        url: `restaurants/${id}/images/${imageid}`,
        method: 'DELETE',
      }),
    }),
    addRestaurantChef: builder.mutation({
      query: ({ id, chefid }) => ({
        url: `restaurants/${id}/chefs/${chefid}`,
        method: 'POST',
      }),
    }),
    deleteRestaurantChef: builder.mutation({
      query: ({ id, chefid }) => ({
        url: `restaurants/${id}/chefs/${chefid}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllRestaurantsQuery,
  useGetRestaurantQuery,
  useCreateRestaurantMutation,
  useUpdateRestaurantMutation,
  useDeleteRestaurantMutation,
  useDeleteRestaurantImageMutation,
  useAddRestaurantChefMutation,
  useDeleteRestaurantChefMutation,
} = restaurantsApi;
