import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Box, Grid, CircularProgress } from '@mui/material';
import { CountryContent } from './formContents/Country';
import { CategoryContent } from './formContents/Category';
import { RestaurantContent } from './formContents/Restaurant';
import { RegionContent } from './formContents/Region';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { UserContent } from './formContents/User';
import ConfirmationDialog from 'app/Dialog';
import { PostContent } from './formContents/Post';
import { useSelector } from 'react-redux';
import { selectUser } from 'services/authSlice';

export function InfoPanel({ data, contentFor, useMutation, useDeleteMutation }) {
  const { id, action } = useParams();
  const user = useSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [open, setOpen] = useState(false);
  const [mutate, results] = useMutation();
  const [deleteMutation, deleteResults] = useDeleteMutation();

  const submit = (event) => {
    event.preventDefault();
    action === 'edit' ? mutate({ data: formValues, id: data.id }) : mutate({ data: formValues });
  };

  useEffect(() => {
    setFormValues(data ?? {});
  }, [data]);

  // useEffect(() => {
  //   console.log(formValues);
  //   console.log(user);
  // }, [formValues]);

  useEffect(() => {
    if (results.isSuccess || deleteResults.isSuccess) {
      enqueueSnackbar(`Successfully ${results.isSuccess ? 'saved' : 'deleted'}`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      results.isSuccess
        ? navigate(`/admin/${contentFor}/edit/${Object.entries(results.data)[0][1]?.id}`)
        : navigate(`/admin/${contentFor}`);
    }
    if (results.isError || deleteResults.isError) {
      enqueueSnackbar('There was an error', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [results, enqueueSnackbar, deleteResults, navigate, contentFor]);

  const titleName = () => {
    switch (contentFor) {
      case 'restaurans':
        return action === 'edit' ? formValues?.name : 'New Restaurant';
      case 'categories':
        return action === 'edit' ? formValues?.name : 'New Category';
      case 'regions':
        return action === 'edit' ? formValues?.name : 'New Region';
      case 'countries':
        return action === 'edit' ? formValues?.name : 'New Country';
      case 'users':
        return action === 'edit' ? formValues?.email : 'New User';
      default:
        return '';
    }
  };

  const renderDelete = () => {
    if (action === 'edit') {
      if (contentFor !== 'users') {
        return (
          <Button
            type="button"
            onClick={() => {
              setOpen(true);
            }}
          >
            Delete
          </Button>
        );
      } else {
        if (user?.id != id) {
          return (
            <Button
              type="button"
              onClick={() => {
                setOpen(true);
              }}
            >
              Delete
            </Button>
          );
        }
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <ConfirmationDialog onAgree={deleteMutation} open={open} setOpen={setOpen} id={data?.id} />
      <Grid container>
        <Grid container direction="row" justifyContent="flex-end" spacing={1}>
          <Grid item xs={4}>
            <Typography variant="h5">{titleName()}</Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <Grid item xs={2}>
              <Button type="submit" onClick={(event) => submit(event)}>
                Save
              </Button>
            </Grid>
            <Grid item xs={6}>
              {renderDelete()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={(event) => submit(event)}>
          {contentFor === 'restaurants' && (
            <RestaurantContent formValues={formValues} setFormValues={setFormValues} />
          )}
          {contentFor === 'categories' && (
            <CategoryContent formValues={formValues} setFormValues={setFormValues} />
          )}
          {contentFor === 'countries' && (
            <CountryContent formValues={formValues} setFormValues={setFormValues} />
          )}
          {contentFor === 'regions' && (
            <RegionContent formValues={formValues} setFormValues={setFormValues} />
          )}
          {contentFor === 'users' && (
            <UserContent formValues={formValues} setFormValues={setFormValues} />
          )}
          {contentFor === 'posts' && (
            <PostContent formValues={formValues} setFormValues={setFormValues} />
          )}
        </form>
      </Grid>
      {(results.isLoading || deleteResults.isLoading) && (
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </Box>
  );
}
