import React, { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Button, Tooltip, IconButton, Avatar, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setToken, setUser } from 'services/authSlice';
import { useGetSelfQuery } from 'services/usersApi';
import Cookies from 'universal-cookie';

export function Navbar() {
  const dispatch = useDispatch();
  const {data} = useGetSelfQuery();
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(setUser(data?.user))
  },[data])

  const [anchorElUser, setAnchorElUser] = useState(null);
  const pages = [
    { name: 'Restaurants', to: '/admin/restaurants' },
    { name: 'Users', to: '/admin/users' },
    { name: 'Categories', to: '/admin/categories' },
    { name: 'Countries', to: '/admin/countries' },
    { name: 'Regions', to: '/admin/regions' },
    { name: 'Posts', to: '/admin/posts' },
  ];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    dispatch(setUser(null));
    dispatch(setToken(null));
    (new Cookies().remove('token', { path: '/' }));
  }

  const handleProfile = () => {
    handleCloseUserMenu();
    navigate(`/admin/users/edit/${user?.id}`);
  } 

  const account = [{label: 'profile', handle: handleProfile},{label: 'logout', handle: handleLogout}];

  
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'red', paddingRight: 0 }}>
        <Toolbar>
          <Typography align="left" component="div" sx={{ flexGrow: 1, maxWidth: 70 }} mr={2} mt={1}>
            <img src={logo} alt="MyTravelDinner logo" />
          </Typography>
          <Typography align="left" component="div" sx={{ flexGrow: 1 }}>
            {pages.map((item) => (
              <Button key={item.name} color="inherit" onClick={() => navigate(item.to)}>
                {item.name}{' '}
              </Button>
            ))}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="User avatar" src={user?.profile?.avatar?.url} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {account.map((setting) => (
                <MenuItem key={setting.label} onClick={setting.handle}>
                  <Typography textAlign="center" sx={{textTransform: 'capitalize'}}>{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
