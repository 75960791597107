import { Button } from '@mui/material';
import React from 'react';

export const CategoryColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name of the category',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    valueGetter: (params) => params.row.name,
  },
  {
    field: 'details',
    headerName: 'Details',
    description: 'Link to the category details',
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      return (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: '10%' }}
            href={`/admin/categories/edit/${params.id}`}
          >
            Edit
          </Button>
        </strong>
      );
    },
  },
];
