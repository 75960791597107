import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
import { API_URL } from './env';

export const postsApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${(new Cookies()).get('token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPosts: builder.query({
      query: () => {
        return { url: `posts` };
      },
    }),
    getPost: builder.query({
      query: (id) => {
        return { url: `posts/${id}` };
      },
    }),
    createPost: builder.mutation({
      query: ({ data }) => ({
        url: 'posts/',
        method: 'POST',
        body: { post: data },
      }),
    }),
    updatePost: builder.mutation({
      query: ({ data, id }) => ({
        url: `posts/${id}`,
        method: 'PUT',
        body: { post: data },
      }),
    }),
    deletePost: builder.mutation({
      query: ({ id }) => ({
        url: `posts/${id}`,
        method: 'DELETE',
      }),
    }),
    translatePost: builder.mutation({
      query: ({ id, data }) => ({
        url: `posts/${id}/translate`,
        method: 'POST',
        body: { translation: data },
      }),
    }),
  }),
});

export const {
  useGetAllPostsQuery,
  useGetPostQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
  useTranslatePostMutation,
} = postsApi;
