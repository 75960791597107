import { Button, Grid, styled, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthLoginMutation } from 'services/authApi';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import { setToken, setUser } from 'services/authSlice';
import map from 'assets/global-map.svg';
import logo from 'assets/logo-login.svg';
import { LoginTheme } from 'services/themes';
import { Helmet } from 'react-helmet';

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.mytraveldinner.com/">
        MyTravelDinner
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [login, results] = useAuthLoginMutation();
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    login({
      data: {
        email: loginForm.email,
        password: loginForm.password,
      },
    });
  };

  useEffect(() => {
    if (results.isSuccess) {
      dispatch(setToken(results.data?.token));
      dispatch(setUser(results.data?.user));
      enqueueSnackbar(`Successfully logged in`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else if (results.isError) {
      setToken(null);
      enqueueSnackbar(`${results?.error?.data?.error?.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [results]);

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#0171bb' }}>
      <Helmet bodyAttributes={{style: 'background-color: #0171bb'}}>
      </Helmet>
      <img
        src={map}
        alt="Global Map"
        style={{ height: '100%', position: 'absolute', top: 0, left: '8%' }}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100vh' }}
      >
        <Grid
          container
          item
          xs={7}
          sx={{ opacity: '0.9', zIndex: 1 }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs sx={{ backgroundColor: 'rgba(0,0,0,0.45)', borderRadius: 5 }}>
            <Container component="main" maxWidth="xs" sx={{ opacity: 1 }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ThemeProvider theme={LoginTheme}>
                  <img src={logo} style={{ maxWidth: 250 }} alt="Login logo" />
                  <Typography
                    component="h1"
                    variant="h4"
                    color={'white'}
                    sx={{ mt: 4, fontWeight: 600, textTransform: 'capitalize' }}
                  >
                    log in
                  </Typography>
                  <Box component="form" sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      color="primary"
                      onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
                      value={loginForm.email}
                      variant="filled"
                      sx={{ borderRadius: 2, backgroundColor: '#fcfcfb' }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      variant="filled"
                      color="primary"
                      onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
                      value={loginForm.password}
                      sx={{ borderRadius: 2, backgroundColor: '#fcfcfb' }}
                    />

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={(e) => handleLogin(e)}
                      type="submit"
                    >
                      Sign In
                    </Button>
                    <Grid container></Grid>
                  </Box>
                </ThemeProvider>
              </Box>
              <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
